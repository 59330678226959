.page-not-found {
  background-color: #f8f5f9;
  &-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    h1 {
      font-size: 230px;
      opacity: 0.1;
    }
    a {
      margin-top: 20px;
    }
    p {
      margin-bottom: 20px;
    }
  }
  @media (max-width: 600px) {
    h1 {
      font-size: 100px;
    }
  }
}
